import * as request from 'superagent';
import * as _ from 'lodash';
import { JsHelpers } from 'helpers/helpers';
import { Widget } from 'modules/widget';

class MultiKpiWidget extends Widget {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._widgetType = 'multikpi';
    }

    generateBlock(blockData) {
        return {
            id: JsHelpers.slugify(blockData.kpi_group),
            title: blockData.kpi_group,
            order: this.widget.blocks.length + 1,
            kpis: [blockData.kpi_code],
            content: []
        };
    }

    generateContent(contentData) {
        return {
            title: contentData.kpi_title,
            kpi: contentData.kpi_code,
            values: []
        };
    }

    addBlock(blockData) {
        let block = _.find(this.widget.blocks, { id: JsHelpers.slugify(blockData.kpi_group) });
        if(_.isUndefined(block)) {
            block = this.generateBlock(blockData);
            this.widget.blocks.push(block);
        } else {
            block.kpis.push(blockData.kpi_code);
        }
        let last = blockData.children.slice(-1)[0];
        // prendo solo ultimo per definizione
        if (last){
            last.kpi_code = blockData.kpi_code;
            last.kpi_title = blockData.kpi_title;
            this.addContent(last, block.id);
        }
    }

    addContent(contentData, blockId) {
        let block = _.find(this.widget.blocks, { id: blockId });
        if(!_.isUndefined(block)) {
            let content = _.find(block.content, { kpi: contentData.kpi_code });
            if(_.isUndefined(content)) {
                content = this.generateContent(contentData);
                block.content.push(content);
            }
            let last = contentData.values.slice(-1)[0];
            // prendo solo ultimo per definizione
            if (last){
                this.addValue(last, content);
            }
        }
    }

    addValue(valueData, content) {
        content.values.push(valueData.formatted_size_symbol);
    }
}

export { MultiKpiWidget }