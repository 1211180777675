
class Toggle {

    constructor(element) {
        this.element = element;
        this.bindEvents();
    }

    bindEvents() {
        this.element.addEventListener('click', (event) => {
            this.element.classList.toggle('is-open');
            this.element.getAttribute('toggle-panel-id');
            this.toggle(this.element.getAttribute('toggle-panel-id'));
        });
    }

    toggle(id) {
        if (id){
            let ids = id.split(',');
            ids.forEach(element => {
                document.getElementById(element).classList.toggle('is-open');
            });
        }
        
    }
}

export { Toggle }