import * as _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Graph } from 'modules/graph';

class FunnelGraph extends Graph {

    constructor(element, config,chartbox) {
        super(element, config, chartbox);
        this._graphType = am4charts.SlicedChart;
        this._series = config.series;
        this.config = config;
    }

    generateSeries() {
        _.each(this._series, seriesConfig => {
            let series = this.graph.series.push(new am4charts.FunnelSeries());
            series.id = seriesConfig.id;
            series.name = seriesConfig.label;
            series.dataFields.value = seriesConfig.data.value;
            series.dataFields.category = seriesConfig.data.category;
            series.legendSettings.labelText = "{value}";
            series.labels.template.text = "{category}: [bold]{value}[/]";
            
            if(this.config.remove_series_labels) {
                series.labels.template.text = "";
            }
                

            series.ticks.template.stroke = am4core.color("white");
            let is_standard_white = false;
            if (document.body.classList.contains('standard-theme')){
                is_standard_white = true;
            }
            if (is_standard_white){
              series.ticks.template.stroke = am4core.color("#333");
              if (document.body.classList.contains('dark')){
                series.ticks.template.stroke = am4core.color("#FFF");
              }
            }

            /*let fillModifier = new am4core.LinearGradientModifier();
            fillModifier.brightnesses = [-0.5, 1, -0.5];
            fillModifier.offsets = [0, 0.5, 1];

            series.slices.template.fillModifier = fillModifier;*/
            series.alignLabels = true;

            series.tooltip.label.adapter.add("textOutput", (text, target, key) => {
                if (target.dataItem) {
                  if (target.dataItem.values.value) {
                    return target.dataItem.properties.category + ': ' + (100 + target.dataItem.values.value.changePercent).toFixed(0) + '%';
                  }
                }
                return text;
              });

            this.series[series.id] = series;
        });
    }
}

export { FunnelGraph }
