import * as request from 'superagent';
import * as _ from 'lodash';
import { JsHelpers } from 'helpers/helpers';
import { Widget } from 'modules/widget';

class UploadDateWidget extends Widget {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._widgetType = 'search';
        this._config = config;
    }

    render() {
        if(this.chartbox) {
            this.chartbox.render();
        }
        this.loading = false;
        this.widget.loading = false;
        this.element.classList.remove('is-loading');
        this.chartbox.element.classList.remove('is-loading');
    }

    fetchData() {
        let _this = this;
        let endpoint = '/import/last_upload_date/1/' + this._config.importType + '/';
        let r = request.get(endpoint)
        .set('Accept', 'application/json')
        .then(response => {
            _this.element.innerHTML = '<i>Dati aggiornati al ' + response.body.upload_date + '</i>';
        });
    }

}

export { UploadDateWidget }