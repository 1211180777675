import { DomHelpers } from 'helpers/helpers';

class Nav {

    constructor() {
        this.element = document.querySelector('.js-nav');
        if(this.element != null) {
            this.menuItemsElements = this.element.querySelectorAll('.js-nav-menu-item');
            this.activeMenuItemElement = this.element.querySelector('.js-nav-menu-item.is-active');
            this.openActiveTree();
            this.bindEvents();
        }
    }

    bindEvents() {
        this.menuItemsElements.forEach((item) => {
            let target = item.getAttribute('href');
            if(target == null) {
                item.addEventListener('click', (event) => {
                    let menu = item.nextElementSibling;
                    if (menu != null) {
                        if(menu.classList.contains('is-open')) {
                            this.closeMenu(menu);
                        } else {
                            this.closeSiblingsMenu(menu);
                            if(item.classList.contains('is-active')) {
                                this.openActiveTree();
                            } else {
                                this.openMenu(menu);
                            }
                        }
                    }
                });
            }
        });
    }

    openMenu(menu) {
        menu.classList.add('is-open');
        let item = menu.previousElementSibling;
        if(item != null) {
            item.classList.add('has-open-menu');
        }
    }

    closeMenu(menu) {
        menu.classList.remove('is-open');
        let item = menu.previousElementSibling;
        if(item != null) {
            item.classList.remove('has-open-menu');
        }
    }

    toggleMenu(menu) {
        menu.classList.toggle('is-open');
        let item = menu.previousElementSibling;
        if(item != null) {
            item.classList.toggle('has-open-menu');
        }
    }

    closeSiblingsMenu(menu) {
        let siblings = DomHelpers.getSiblings(menu, '.js-nav-menu');
        siblings.forEach((menu) => {
            this.closeMenu(menu);
            menu.querySelectorAll('.is-open').forEach((subMenu) => {
                this.closeMenu(subMenu);
            });
        });
    }

    openActiveTree() {
        if(this.activeMenuItemElement != null) {
            let menu = this.activeMenuItemElement.parentNode;
            while(menu) {
                let menuItem = menu.previousElementSibling;
                if(menuItem != null) {
                    menuItem.classList.add('is-active');
                }
                this.openMenu(menu);
                let parentMenu = menu.parentNode;
                if(parentMenu.classList.contains('js-nav-menu')) {
                    menu = parentMenu
                } else {
                    menu = null;
                }
            }
        }
    }

}

export { Nav }
