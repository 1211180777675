import * as request from 'superagent';
import * as _ from 'lodash';
import { JsHelpers } from 'helpers/helpers';
import { Widget } from 'modules/widget';

class SearchSingleWidget extends Widget {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._widgetType = 'search';
        this._endpoint_search = '/bi/search_filter_item/1/';
        this._endpoint = '/bi/tree/';
        this._config = config; 
    }

    /*render() {
        if(this.chartbox) {
            this.chartbox.render();
        }
    }*/

    generateBlock(blockData) {
        return {
            id: JsHelpers.slugify(blockData.kpi_group),
            title: blockData.kpi_group,
            order: this.widget.blocks.length + 1,
            kpis: [blockData.kpi_code],
            content: []
        };
    }

    generateContent(contentData) {
        return {
            title: contentData.kpi_title,
            kpi: contentData.kpi_code,
            values: []
        };
    }

    addBlock(blockData) {
        let block = _.find(this.widget.blocks, { id: JsHelpers.slugify(blockData.kpi_group) });
        if(_.isUndefined(block)) {
            block = this.generateBlock(blockData);
            this.widget.blocks.push(block);
        } else {
            block.kpis.push(blockData.kpi_code);
        }
        let last = blockData.children.slice(-1)[0];
        // prendo solo ultimo per definizione
        if (last){
            last.kpi_code = blockData.kpi_code;
            last.kpi_title = blockData.kpi_title;
            this.addContent(last, block.id);
        }
    }

    addContent(contentData, blockId) {
        let block = _.find(this.widget.blocks, { id: blockId });
        if(!_.isUndefined(block)) {
            let content = _.find(block.content, { kpi: contentData.kpi_code });
            if(_.isUndefined(content)) {
                content = this.generateContent(contentData);
                block.content.push(content);
            }
            let last = contentData.values.slice(-1)[0];
            // prendo solo ultimo per definizione
            if (last){
                this.addValue(last, content);
            }
        }
    }

    addValue(valueData, content) {
        content.values.push(valueData.formatted_size_symbol);
    }

    fetchData() {
        let _this = this;

        let requests = [];
        // PREPARE REQUESTS BASED ON KPI CONFIG
        _.each(this._kpis, kpi => {
            let params = {
                kpi: kpi.key,
                menu_id: this._menuId,
                from_who: 1,
                debug: window.agregg.debugMode
            };
            if(this._drillDown != undefined) {
                params['drill_down_id'] = this._drillDown;
            }
            if(this._period != undefined) {
                params['per'] = this._period;
            }
            let r = request.get(this._endpoint)
            .set('Accept', 'application/json')
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });
            requests.push(r);
        });
        // HANDLE REQUESTS RESPONSES
        Promise.all(requests).then(responses => {
            _.each(responses, blockData => {
                this.addBlock(blockData);
            });

            this.widget.loading = false;
            this.render();

            let gutterElement = document.createElement('div');
            gutterElement.classList.add('c-widget__gutter');
            _this.element.appendChild(gutterElement);

            let searchContainerParent = document.createElement("div");
            searchContainerParent.classList.add('c-widget__block');
            let searchContainer = document.createElement("div");
            searchContainer.classList.add('c-widget__content');
            /*searchContainer.classList.add('o-grid');
            searchContainer.classList.add('o-grid--small');
            searchContainer.classList.add('o-grid--contentcenter');
            searchContainer.classList.add('o-grid--wrap');*/

            let title = document.createElement("div");
            title.classList.add('c-widget__title');
            title.innerHTML = _this._config.extra_conf.title;
            searchContainerParent.appendChild(title);

            let subtitle = document.createElement("label");
            subtitle.innerHTML = _this._config.extra_conf.subtitle;
            searchContainer.appendChild(subtitle);

            let inputs = document.createElement("div");
            inputs.setAttribute('style', "width: 100%");
            let inputsContainer = document.createElement("div");
            inputsContainer.classList.add('search_list--widget');
            inputsContainer.classList.add('o-grid');
            inputsContainer.classList.add('o-grid--wrap');
            inputsContainer.classList.add('o-grid--small');
            inputsContainer.classList.add('o-grid--contentcenter');
            
            inputs.appendChild(inputsContainer);

            _.each(_this._config.extra_conf.search, (block, index) => {

                let searchdiv = document.createElement("div");
                searchdiv.classList.add('o-grid__item');
                searchdiv.classList.add('u-1/1');
                searchdiv.classList.add('u-1/2@md');
                searchdiv.classList.add('u-4/12@lg');
                searchdiv.classList.add('u-margin-xsmall--vertical');
                //searchdiv.classList.add('u-margin-none--bottom@xs');

                let mi = document.createElement("input");
                mi.setAttribute('type', 'text');
                mi.setAttribute('class', 'search_list');
                mi.classList.add('c-input');
                mi.classList.add('c-input--full');
                mi.classList.add('c-input--bordered'); 
                mi.setAttribute('placeholder', block);
                mi.setAttribute('name', index);

                mi.onkeydown = function(e){
                    if(e.keyCode == 13){
                        _this.search();
                    }
                };

                searchdiv.appendChild(mi);
                inputsContainer.appendChild(searchdiv);
                //searchContainer.appendChild(searchdiv);
            });

            let midiv = document.createElement("div");
            midiv.classList.add('o-grid__item');
            midiv.classList.add('u-1/1');
            midiv.classList.add('u-6/12@md');
            midiv.classList.add('u-4/12@lg');
            midiv.classList.add('u-margin-xsmall--vertical');
            //midiv.classList.add('u-'+ space +'/12');
            //midiv.classList.add('u-1/1@xs');

            let mi = document.createElement("input");
            mi.setAttribute('type', 'submit');
            mi.setAttribute('value', 'Cerca');
            mi.classList.add('c-btn');
            mi.classList.add('c-btn--small');
            mi.classList.add('c-btn--full');
            mi.classList.add('c-btn--white');
            mi.classList.add('c-btn--uppercase');

            mi.onclick = function () {
                _this.search(); 
            }
            
            midiv.appendChild(mi);
            inputsContainer.appendChild(midiv);

            //searchContainer.appendChild(inputsContainer);
            searchContainerParent.appendChild(searchContainer);
            searchContainerParent.appendChild(inputs);
            _this.element.appendChild(searchContainerParent); 

           
        });

    }

    search() {
        let _this = this;
        let searchkeys = {};

        let inputs = document.querySelectorAll('.search_list');
        if(inputs != null) {
            inputs.forEach((input) => {
                if (input.value){
                    searchkeys[input.getAttribute("name")] = input.value;
                }
            }) 
        }

        let link = '/cms/menu/1/' + _this._config.extra_conf.dashboard_result + '/?search_from_url=true';

        _.each(searchkeys, (value, key) => {
            link += '&' + key + '=' + value;
        })
        
        window.location.href = link;

    }
}

export { SearchSingleWidget }