import * as _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Map } from 'modules/map';

class BaseMap extends Map {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
    }


}

export { BaseMap }
