import * as _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Graph } from 'modules/graph';

class PieGraph extends Graph {

    constructor(element, config,chartbox) {
        super(element, config, chartbox);
        this._graphType = am4charts.PieChart;
        this._series = config.series;
        this.config = config;
    }

    generateSeries() {
        _.each(this._series, seriesConfig => {
            let series = this.graph.series.push(new am4charts.PieSeries());
            switch(this.config.type) {
                case 'donut':
                    this.graph.innerRadius = am4core.percent(40);
                    break;
            }
            series.id = seriesConfig.id;
            series.name = seriesConfig.label;
            series.dataFields.value = seriesConfig.data.value;
            series.dataFields.category = seriesConfig.data.category;
            series.legendSettings.text = "{category}";
            series.legendSettings.itemValueText = " ";

            //series.ticks.template.disabled = true;
            //series.alignLabels = false;
            if (!seriesConfig.show_percentage){
                series.labels.template.text = "{value}";
            }
            else {
                if (this.config.numOfDecimals == 0){
                    series.labels.template.text = "{value.percent.formatNumber('#,###.')}%";
                }
                else if (this.config.numOfDecimals == 1){
                    series.labels.template.text = "{value.percent.formatNumber('#,###.0')}%";
                }
                else if (this.config.numOfDecimals == 2){
                    series.labels.template.text = "{value.percent.formatNumber('#,###.00')}%";
                }
                else {
                    series.labels.template.text = "{value.percent.formatNumber('#,###.0')}%";
                }
            }

            this.graph.radius = am4core.percent(60);
            //series.labels.template.radius = am4core.percent(10);
            let is_standard_white = false;
            if (document.body.classList.contains('standard-theme')){
                is_standard_white = true;
            }
            series.labels.template.fill = am4core.color("white");
            series.ticks.template.stroke = am4core.color("white");
            if (is_standard_white){
                series.labels.template.fill = am4core.color("#333");
                series.ticks.template.stroke = am4core.color("#333");
                if (document.body.classList.contains('dark')){
                    series.labels.template.fill = am4core.color("#FFF");
                    series.ticks.template.stroke = am4core.color("#FFF");
                }
            }
            

            /*series.labels.template.adapter.add("opacity", function(opacity, target) {
                if (target.dataItem && target.dataItem.values.value  && (target.dataItem.values.value.percent < 8)) {
                  return 0;
                }
                else if (isNaN(target.dataItem.values.value.value)){
                    return 0;
                }
                return opacity;
            });*/

            this.series[series.id] = series;
        });
    }
}

export { PieGraph }
