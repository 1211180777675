import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import scssVars from 'helpers/scss-var-mapper';

function am4themes_agregg(target) {

    let darkcolor = '#333';
    let whitecolor = '#FFF';
    let is_standard_white = false;
    let is_standard_dark = false;
    if (document.body.classList.contains('standard-theme')){
        is_standard_white = true;
        if (document.body.classList.contains('dark')){
            is_standard_dark = true;
            darkcolor = '#FFF';
            whitecolor = '#333';
        }
    }

    // Graph
    if(target instanceof am4core.ColorSet) {
        if (is_standard_white){
            target.list = [];
            for (var i in window.colors_array){
                var color = window.colors_array[i];
                target.list.push(am4core.color(color));
            }
        }
        else {
            target.list = [
                am4core.color(scssVars.palette['graph-glareblue']),
                am4core.color(scssVars.palette['graph-lightblue']),
                am4core.color(scssVars.palette['graph-blue']),
                am4core.color(scssVars.palette['graph-petrol']),
                am4core.color(scssVars.palette['graph-beige']),
                am4core.color(scssVars.palette['graph-yellow']),
                am4core.color(scssVars.palette['graph-blonde']),
                am4core.color(scssVars.palette['graph-lightblue-2']),
                am4core.color(scssVars.palette['graph-lightblue-1'])
            ];
        }
    }

    if (target instanceof am4core.InterfaceColorSet) {
        if (is_standard_white){
            target.setFor('primaryButton', whitecolor);
            target.setFor('primaryButtonHover', whitecolor);
            target.setFor('primaryButtonDown', whitecolor);
            target.setFor('primaryButtonActive', whitecolor);
            target.setFor('primaryButtonText', darkcolor);
            target.setFor('text', darkcolor);
            target.setFor('alternativeText', darkcolor);
            target.setFor('disabledBackground', whitecolor);
            target.setFor('positive', 'green');
            target.setFor('negative', 'red');
        }
        else {
            target.setFor('primaryButton', scssVars.palette['darkgrey']);
            target.setFor('primaryButtonHover', scssVars.palette['darkgrey-glare']);
            target.setFor('primaryButtonDown', scssVars.palette['darkgrey']);
            target.setFor('primaryButtonActive', scssVars.palette['darkgrey']);
            target.setFor('primaryButtonText', scssVars.palette['white-shade']);
            target.setFor('text', scssVars.palette['white-shade']);
            target.setFor('alternativeText', scssVars.palette['white']);
            target.setFor('disabledBackground', scssVars.palette['darkgrey']);
            target.setFor('positive', scssVars.palette['graph-green']);
            target.setFor('negative', scssVars.palette['graph-red']);
        }
    }

    if(target instanceof am4charts.Chart) {
        target.marginTop = 0;
        target.marginBottom = 0;
        target.marginLeft = 0;
        target.marginRight = 0;
        target.paddingTop = 10;
        target.paddingBottom = 0;
        target.paddingLeft = 0;
        target.paddingRight = 0;
    }

    if(target instanceof am4core.Tooltip) {
        target.background.cornerRadius = 8;
        target.background.strokeOpacity = 0;
        target.background.fill = am4core.color(scssVars.palette['darkgrey']);
        if (is_standard_white){
            target.background.fill = am4core.color(whitecolor);
        }
        target.dy = -10;
        target.getFillFromObject = false;
    }

    if(target instanceof am4charts.Cursor) {
        target.strokeWidth = 0;
        target.cursorDownStyle = am4core.MouseCursorStyle.pointer;
        target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    }

    if(target instanceof am4charts.Legend) {
        target.fill = am4core.color(scssVars.palette['white-shade']);
        if (is_standard_white){
            target.fill = am4core.color(darkcolor);
        }
        target.fontWeight = 300;
    }


    if(target instanceof am4charts.ColumnSeries) {
        target.strokeWidth = 0;
    }

    if(target instanceof am4charts.PieSeries) {
        //target.ticks.template.disabled = true;
        //target.labels.template.disabled = true;
    }

    if(target instanceof am4charts.Axis) {
        target.minWidth = 16;
        target.minHeight = 16;
        target.marginTop = 5;
        target.marginBottom = 5;
        target.marginLeft = 5;
        target.marginRight = 5;
    }

    // Axes
    if(target instanceof am4charts.AxisRenderer) {

        // Label positioning
        target.minLabelPosition = 0;
        target.maxLabelPosition = 1;
        target.minGridDistance = 40;

        // Grid
        target.grid.template.strokeWidth = 1;
        target.grid.template.strokeOpacity = 1;
        target.grid.template.stroke = am4core.color(scssVars.palette['graph-grid-color']);

        // Labels
        target.labels.template.fill = am4core.color(scssVars.palette['white-shade']);
        if (is_standard_white){
            target.labels.template.fill = am4core.color(darkcolor);
        }
        target.labels.template.fontSize = '14px';
        target.labels.template.fontWeight = 300;
        target.labels.template.marginTop = 0;
        target.labels.template.marginBottom = 0;
        target.labels.template.marginLeft = 0;
        target.labels.template.marginRight = 0;
        target.labels.template.paddingTop = 0;
        target.labels.template.paddingBottom = 0;
        target.labels.template.paddingLeft = 0;
        target.labels.template.paddingRight = 0;
        target.labels.template.minHeight = 16;
        target.labels.template.minWidth = 16;
    }
}

export default am4themes_agregg;