import * as request from 'superagent';
import * as _ from 'lodash';
import { JsHelpers } from 'helpers/helpers';

class PPTX {

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    async start(all_charts) {

        // open modal
        if (document.getElementById('create_pptx')){
            document.getElementById('create_pptx').remove();
        }

        let temp = document.createElement('div');
        temp.innerHTML = `<div id="create_pptx" class="c-modal c-modal--visible">
            <div class="c-modal__inner c-modal--reveal">
                <div class="c-modal__close js-close-modal">x</div>
                <div class="c-chartbox">
                    <h1 class="c-chartbox__header u-text--center" style="font-size:40px;margin-bottom: 0; display: block;">Stiamo generando il report PDF</h1>
                    <div class="c-loading">
                        <span class="c-loading__dot"></span>
                        <span class="c-loading__dot"></span>
                        <span class="c-loading__dot"></span>
                    </div>
                </div>
            </div>

            </div>`;

        document.body.appendChild(temp);
        document.body.classList.add('c-modal__body')

        document.querySelector( '.js-close-modal' ).addEventListener( 'click', this.closeModal , false );

        if (document.querySelectorAll('.is-loading:not(.ignore-pptx)').length > 0 ){

            if(document.getElementById('create_pptx').querySelectorAll('.c-loading')[0]) {
                document.getElementById('create_pptx').querySelectorAll('.c-loading')[0].remove();
                document.getElementById('create_pptx').querySelectorAll('.c-chartbox__header')[0].remove();
            }
            var textnode = document.createElement("div");
            textnode.innerHTML = '<h1 \
                class="c-chartbox__header u-text--center" style="font-size:40px;margin-bottom: 0; display:block;">Per favore attendi il caricamento di tutti gli elementi della pagina prima di scaricare il PDF</h1>\
               ';
            document.getElementById('create_pptx')
                .querySelectorAll('.c-chartbox')[0].appendChild(textnode);

            
        }
        else {
        
            let charts_data = [];

            await this.asyncForEach(all_charts, async (chart) => {

                let graph_id = chart.getAttribute('data-graph-id');
                if (graph_id){
                    let var_chart = this.init(graph_id);
                    charts_data.push(var_chart);
                }

                await this.sleep(510);
            })

            Promise.all(charts_data).then(chart_data => {
                
                if (chart_data != false){
                    let dashboard_name = document.querySelectorAll('.c-content__header__title')[0].textContent;
                    var dashboard_data =
                    {
                        d_id: 0,
                        d_title: dashboard_name,
                        d_date: "",
                        d_components: chart_data,
                    };

                    let data = {
                        'screenshot_data' : JSON.stringify(dashboard_data),
                        'request_url': 'true',
                        'current_url': window.location.href,
                        'gff': JsHelpers.gup('gff').join(','),
                        'csrfmiddlewaretoken': crsf_tmp,
                    }

                    let r = request.post('/export/export_pptx/1/')
                        .type('form')
                        .send(data)
                        .then(res => {
                            if (res.body && res.body.name){
                                if(document.getElementById('create_pptx').querySelectorAll('.c-loading')[0]) {
                                    document.getElementById('create_pptx').querySelectorAll('.c-loading')[0].remove();
                                    document.getElementById('create_pptx').querySelectorAll('.c-chartbox__header')[0].remove();
                                }
                                var textnode = document.createElement("div");
                                var url = ('/media/'+res.body.name ).replace(/\/\//g, "/");
                                textnode.innerHTML = '<h1 \
                                    class="c-chartbox__header u-text--center" style="font-size:40px;margin-bottom: 0; display:block;">PDF creato con successo</h1>\
                                    <p style="font-size: 18px;padding: 0 15px 15px 15px;text-align:center;">A breve riceverai il report richiesto nella tua casella email:<br> \
                                    '+window.user_email+'<p>\
                                    <p class="u-text--center" style="font-size: 18px; padding: 0 15px 15px 15px;">\
                                    Puoi sempre scaricarlo subito cliccando qui: \
                                    <div class="u-text--center"><a class="c-btn c-btn--small c-btn--white c-btn--uppercase" target="_blank" href="'+url+'">\
                                    <svg class="o-icon u-margin-alt-small--right" ><use xlink:href="#icon-download"></use>\
                                    </svg>Scarica PDF</a></div></p>';
                                document.getElementById('create_pptx')
                                    .querySelectorAll('.c-chartbox')[0].appendChild(textnode);
                            }
                            else {
                                if(document.getElementById('create_pptx').querySelectorAll('.c-loading')[0]) {
                                    document.getElementById('create_pptx').querySelectorAll('.c-loading')[0].remove();
                                    document.getElementById('create_pptx').querySelectorAll('.c-chartbox__header')[0].remove();
                                }
                                var textnode = document.createElement("div");
                                textnode.innerHTML = '<h1 class="c-chartbox__header">PDF non creato, per favore contatta l\'assistenza</h1>';
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            document.getElementById('create_pptx').querySelectorAll('.c-loading')[0].remove();
                            document.getElementById('create_pptx').querySelectorAll('.c-chartbox__header')[0].remove();
                            var textnode = document.createElement("div");
                            textnode.innerHTML = '<h1 class="c-chartbox__header">PDF non creato, per favore contatta l\'assistenza riportando il seguente errore:</h1><p>' + error + '</p>';
                        });
                        ;
                    
                    return dashboard_data;
                }
            });

        }

    }

    closeModal(){
        let modalOpen = document.querySelector( '.c-modal.c-modal--visible' );
        modalOpen.querySelector( '.c-modal__inner' ).classList.remove( 'c-modal--reveal' );
        modalOpen.classList.remove( 'c-modal--visible' );
        document.body.classList.remove('c-modal__body');
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async init(graph_id){
        let currentGraphContainer = document.querySelectorAll('[data-graph-id="'+graph_id+'"]');
        if (currentGraphContainer && currentGraphContainer.length > 0){
            let t = currentGraphContainer[0].getAttribute('data-tab-item-index');
            if (t){
                document.querySelectorAll('[data-tabby-bar-full="' + t + '"]')[0].click();
            }
        }
        // TODO qui dovrei avere un delay di circa un secondo per permettere al tab di caricarsi correttamente!
        await this.sleep(500);
        
        // GESTIONE AGENZIE
        if (currentGraphContainer && currentGraphContainer.length > 0){
            if (currentGraphContainer[0].classList.contains('gr-63')) {
                if (currentGraphContainer[0].classList.contains('u-hide')){
                    let menu_id = currentGraphContainer[0].getAttribute('data-menu-id');
                    let item = {};
                    item.item_id = '1';
                    item.item_type = 'text';
                    item.graph_width = 200;
                    item.graph_height = 100;
                    item.item_html = 'Nessuna gestione disponibile';

                    let r = {
                        comp_title: '',
                        comp_origin_title: ' ',
                        comp_width: 12,
                        comp_graph_id: graph_id,
                        comp_items: [item],
                        comp_text: ' ',
                        menu_id: menu_id,
                        item_type: 'table',
                    };

                    return r;
                }
            }
        }

        //console.log(graph_id);
        let svg = currentGraphContainer[0].querySelectorAll('.js-graph');
        let svg2 = currentGraphContainer[0].querySelectorAll('.js-map');
        if (svg2 && svg2.length > 0){
            svg = svg2;
        }
        if (svg && svg.length > 0){
            svg = svg[0].getElementsByTagName('svg');
        }
        let table = currentGraphContainer[0].getElementsByTagName('table')[0];
        let item = {};

        if (svg && svg.length > 0){
            svg = svg[0];

            let d = currentGraphContainer[0].getBoundingClientRect();
            svg.setAttribute('width', d.width);
            svg.setAttribute('height', d.height);

            svg.querySelectorAll('[role="button"]').forEach(function(a){
                a.remove()
            })

            svg.querySelectorAll('[fill="#ffffff"]').forEach(function(a){
                a.remove()
            })

            svg.querySelectorAll('.amcharts-amexport-menu').forEach(function(a){
                a.remove()
            })
            svg.querySelectorAll('[stroke="#000000"]').forEach(function(a){
                a.remove()
            })

            svg.querySelectorAll('[opacity="0"]').forEach(function(a){
                a.remove()
            })

            svg.querySelectorAll('[role="tooltip"]').forEach(function(a){
                a.remove()
            })

            svg.querySelectorAll('[display="none"]').forEach(function(a){
                a.remove()
            })

            svg.querySelectorAll('[fill="#000000"]').forEach(function(a){
                a.querySelectorAll('path').forEach(function(b){
                    // TODO questo è da applicare in maniera intelligente ai radar graphs..
                    b.setAttribute('fill', '#ffffff');
                });
            })

            let serializer = new XMLSerializer();
            svg = serializer.serializeToString(svg);

            svg = svg.replace(/eceff1/g, "333");

            item.item_id = '1';
            item.item_type = 'svg';
            item.graph_width = 1000;
            item.graph_height = 1000;
            item.item_html = svg;
        }
        else if (table){
            let w = table.clientWidth;
            let h = table.clientHeight;

            this.applyStyle(table);

            if (currentGraphContainer[0].classList.contains('gr-63')) {
                console.log('remove last row in agenzie aggregata..');
                table = table.cloneNode(true);
                var rowCount = table.rows.length;
                //var row = table.rows[rowCount - 1 ];
                table.deleteRow(rowCount - 1);

                var rows = table.rows;
                for (var i = 0, len = rows.length; i < len; i++) {
                    rows[i].removeChild(rows[i].childNodes[0]);
                }

                w = 700;
            }

            let serializer = new XMLSerializer();
            table = serializer.serializeToString(table);
            
            item.item_id = '1';
            item.item_type = 'table';
            item.graph_width = w;
            item.graph_height = h;
            item.item_html = table;
        }
        else {
            item.item_id = '1';
            item.item_type = 'text';
            item.graph_width = 1000;
            item.graph_height = 1000;
            item.item_html = currentGraphContainer[0].innerText;
        }

        let title = '';
        try {
            title = document.querySelectorAll('[data-graph-id="'+graph_id+'"]')[0].querySelectorAll('.c-chartbox__title')[0].textContent;
        }
        catch (e){
            var t = document.querySelectorAll('[data-graph-id="'+graph_id+'"]')[0].getAttribute('data-tab-item-index');
            if (t) {
                title = document.querySelectorAll('[data-tabby-bar-full="'+t+'"]')[0].innerText;
            }
        }

        let menu_id = currentGraphContainer[0].getAttribute('data-menu-id');

        let r = {
            comp_title: title,
            comp_origin_title: ' ',
            comp_width: 12,
            comp_graph_id: graph_id,
            comp_items: [item],
            comp_text: ' ',
            menu_id: menu_id,
        };

        return r;
    }

    extend(a, b){
        var c = {};
        for(var p in a)
            c[p] = (b[p] == null) ? a[p] : b[p];
        return c;
    }

    applyStyle(el) {
        try {
            let s = getComputedStyle(el);
        
            for (let key in s) {
                let prop = key.replace(/\-([a-z])/g, v => v[1].toUpperCase());
                //if (['height', 'border-radius', 'white-space' ,'border-bottom-width', 'border-bottom', 'border' , 'margin', 'padding', 'width', 'max-width', 'min-width', 'border-collapse', 'border-spacing', 'table-layout', 'font-family', 'font-style', 'font-size', 'font-weight', 'line-height', 'text-align', 'text-decoration', 'text-indent', 'text-overflow', 'text-transform', 'word-wrap', 'vertical-align', 'color', 'background', 'background-color', 'bottom', 'clear','display', 'float', 'left', 'opacity', 'overflow', 'position', 'right', 'top', 'visibility', 'margin', 'padding']
                if (['height', 'width']
                    .indexOf( prop ) > - 1) {
                        try {
                            el.style[prop] = s[key];
                        }
                        catch (e){}
                }
            }
            el.style['background'] = el.style.backgroundColor;
            el.style['border-radius'] = el.style.borderRadius;

            /*for (let i in el.childNodes){
                this.applyStyle(el.childNodes[i]);
            }*/
        }
        catch (e){}
    }
    

} export { PPTX }