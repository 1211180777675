import scssVarsTua from '../../css/base/settings_tua.scss';
import scssVars from '../../css/base/settings.scss';

function mapVars (exportedVars) {
    if (scssVarsPath == 'tua'){
        exportedVars = scssVarsTua;
    }
    let map = {};

    for(let key in exportedVars) {
        let chunks = key.split('-');
        let groupKey = chunks.shift();
        let itemKey = chunks.join('-');

        if(map[groupKey] === undefined) {
            map[groupKey] = {};
        }
        map[groupKey][itemKey] = exportedVars[key];
    }

    return map;
};

export default mapVars(scssVars);