
class CheckUncheck {

    constructor(element) {
        this.element = element;
        
        this.bindEvents();
    }

    bindEvents() {
        this.element.addEventListener('click', (event) => {
            let parent = this.element.parentElement;
            let periodsContainer = parent.parentElement.parentElement.querySelector('.js-periods');
            let year = this.element.getAttribute('data-year');
            let checkboxes = periodsContainer.querySelectorAll('[data-year="'+year+'"]');
            periodsContainer.classList.add('is-open');
            //console.log(periodsContainer.classList);
            parent.classList.add('is-open');
            //console.log(parent.classList);
            checkboxes.forEach(checkbox => {
                if(this.element.checked) {
                    checkbox.checked = true;
                }
                else {
                    checkbox.checked = false;
                } 
            });
            event.stopPropagation();
        });
    }
}

export { CheckUncheck }