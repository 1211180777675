class Dropdown {

    constructor(element) {
        this.element = element;
        this.toggleElement = element.querySelector('.js-dropdown-toggle');
        this.bindEvents();
    }

    bindEvents() {
        this.toggleElement.addEventListener('click', (event) => {
            this.toggle();
            //if(this.element.classList.contains('is-open')) {
                this.documentClickCallbackBound = this.documentClickCallback.bind(this);
                document.addEventListener('click', this.documentClickCallbackBound);   
            //}
            
        });
    }

    toggle() {
        this.element.classList.toggle('is-open');
    }

    open() {
        this.element.classList.add('is-open');
    }

    close() {
        this.element.classList.remove('is-open');
    }

    documentClickCallback(event) {
        if(!this.element.contains(event.target)) {
            this.toggle();
            document.removeEventListener('click', this.documentClickCallbackBound);
        }
    }
}

export { Dropdown }