import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import scssVars from 'helpers/scss-var-mapper';

function am4adapters_agregg() {

    // am4core.globalAdapter.addAll(am4core.Tooltip, 'fontSize', function(value, target) {
    //     return '14px';
    // });

    am4core.globalAdapter.addAll(am4core.Tooltip, 'fontWeight', function(value, target) {
        return 300;
    });

    // am4core.globalAdapter.addAll(am4core.Tooltip, 'pointerOrientation', function(value, target) {
    //     return 'vertical';
    // });

    // am4core.globalAdapter.addAll(am4core.Label, 'fill', function(value, target) {
    //     return am4core.color(scssVars.palette['white-shade']);
    // });

}

export default am4adapters_agregg;