import * as _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Graph } from 'modules/graph';

class RadarGraph extends Graph {

    constructor(element, config,chartbox) {
        super(element, config, chartbox);
        this._graphType = am4charts.RadarChart;
        this._axes = config.axes;
        this._series = config.series;
        this.config = config;
    }

    generateAxes() {
        _.each(this._axes, axisConfig => {
            let axis = null;
            switch(axisConfig.position) {
                case 'top':
                case 'bottom':
                    axis = this.graph.xAxes.push(new am4charts.CategoryAxis());
                    axis.dataFields.category = axisConfig.category;
                    axis.tooltip.disabled = true;
                    axis.renderer.tooltip.disabled = true;

                    let label = axis.renderer.labels.template;
                    label.wrap = true;
                    label.maxWidth = 80;
                    label.textAlign = 'middle';

                    axis.events.on("sizechanged", function(ev) {
                        let in_axis = ev.target;
                        in_axis.renderer.labels.template.maxWidth = 80;
                        in_axis.renderer.labels.template.wrap = true;
                    });

                    break;
                case 'left':
                case 'right':
                    axis = this.graph.yAxes.push(new am4charts.ValueAxis());
                    if (axisConfig.show_percentage){
                        axis.min = 0;
                        axis.max = 100;
                        axis.strictMinMax = true;
                        axis.calculateTotals = true;
                    }
                    if (this.config.numOfDecimals && this.config.numOfDecimals != 0 && this.config.numOfDecimals < 10){
                        axis.max = parseInt(this.config.numOfDecimals); 
                        axis.min = 0; 
                        axis.strictMinMax = true;

                        function createGrid(value) {
                            var range = axis.axisRanges.create();
                            range.value = value;
                            range.label.text = "{value}";
                        }
                        for (var i = 0; i <= parseInt(this.config.numOfDecimals); i++ ){
                            createGrid(i);
                        }
                    }
                    axis.tooltip.disabled = true;
                    axis.renderer.tooltip.disabled = false;
                    break;
            }

            axis.id = axisConfig.id;
            axis.title.text = axisConfig.label;
            this.axes[axis.id] = axis;
        });

    }

    generateSeries() {
        _.each(this._series, seriesConfig => {
            let series = this.graph.series.push(new am4charts.RadarSeries());
            series.id = seriesConfig.id;
            series.name = seriesConfig.label;
            
            series.dataFields.valueY = "displayValue";
            series.dataFields.categoryX = "anno";
            series.strokeWidth = 3;
            series.tooltipText = "[bold]{categoryX}[/] {valueY}";

            this.graph.radius = am4core.percent(60);

            if (window.innerWidth < 1000) {
                this.graph.radius = am4core.percent(50);
            }

            this.graph.cursor = new am4charts.RadarCursor();
            this.graph.cursor.lineY.disabled = true;
            this.graph.cursor.lineX.disabled = true;

            this.series[series.id] = series;
        });
    }
}

export { RadarGraph }
