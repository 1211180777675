export class PrepareTabs {
    constructor(container) {
        this.container = container;
        this.init();
    }

    init() { 
        let tabContainerIndex = this.container.getAttribute('data-tab-index');
        let tabs = this.container.querySelector(`[data-tab-tabs-index]`);
        let container = this.container.querySelector(`[data-tab-content-index]`);

        let tabGraphs = document.querySelectorAll('[data-tab-item-index]');
        let thisTabsElements = [];

        tabGraphs.forEach((graph) => {
            let graphTabInfo = graph.getAttribute('data-tab-item-index').split('_');
            
            graph.setAttribute('data-tab-item-order', graphTabInfo[1]);
            graph.setAttribute('data-tab-width', graph.getAttribute('data-graph-width'));


            if(graphTabInfo[0] == tabContainerIndex){
                if (!thisTabsElements.hasOwnProperty(graphTabInfo[1])){
                    thisTabsElements[graphTabInfo[1]] = [];
                }

                thisTabsElements[graphTabInfo[1]].push(graph);
            }
        })

        thisTabsElements.forEach(graphs => {

            let tabItem = document.createElement('div');
            //let isActive = graph.getAttribute('data-tab-item-order') == 1 ? 'data-tabby-active=""' : '';
            tabItem.setAttribute('data-tabby-bar-full',graphs[0].getAttribute('data-tab-item-index'));
            tabItem.setAttribute('data-tabby-bar',graphs[0].getAttribute('data-tab-item-order'));
            if(graphs[0].getAttribute('data-tab-item-order') == 1 ){
                tabItem.setAttribute('data-tabby-active','');
            }
            
            tabItem.innerHTML = graphs[0].getAttribute('data-tab-title').replace('show|', '');
            tabs.appendChild(tabItem);

            graphs.forEach( (graph, index) => {

                let tabItemContent = document.createElement('div');
                tabItemContent.setAttribute('data-tabby-content',graph.getAttribute('data-tab-item-order'));
                tabItemContent.classList.add("o-grid__item")
                tabItemContent.classList.add("u-1/1")
                tabItemContent.classList.add("u-" + graph.getAttribute('data-tab-width') + "/12@md")
                
                if(graph.getAttribute('data-tab-item-order') == 1  ){
                    tabItemContent.setAttribute('data-tabby-active','');
                }
                if(graph.getAttribute('data-tab-title') != "" && index != 0 ){
                    let sectionTitle = document.createElement('h4');
                    sectionTitle.classList.add("u-padding-alt-small--horizontal");
                    sectionTitle.classList.add("u-padding--top");
                    sectionTitle.classList.add("u-margin-none--bottom");
                    sectionTitle.textContent = graph.getAttribute('data-tab-title');
                    tabItemContent.appendChild(sectionTitle);
                }
                //console.log(graph.getAttribute('data-tab-title'), graph.getAttribute('data-tab-title').startsWith('show|'), graph.getAttribute('data-tab-title') != "" && index == 0 && graph.getAttribute('data-tab-title').startsWith('show|'))
                if(graph.getAttribute('data-tab-title') != "" && index == 0 && graph.getAttribute('data-tab-title').startsWith('show|') ){
                    let sectionTitle = document.createElement('h4');
                    sectionTitle.classList.add("u-padding-alt-small--horizontal");
                    sectionTitle.classList.add("u-padding--top");
                    sectionTitle.classList.add("u-margin-none--bottom");
                    sectionTitle.textContent = graph.getAttribute('data-tab-title').replace('show|', '');
                    tabItemContent.appendChild(sectionTitle);
                    //console.log(sectionTitle.textContent);
                }
                tabItemContent.appendChild(graph);

                container.appendChild(tabItemContent);

                let graphId = graph.getAttribute('data-tab-graph-id');
                document.getElementById(graphId).remove();
            });
        });
    }
}  



export class Tabs {
    constructor(container) {
        this.container = container;
        this.index = 0;
        this.items = [];

        this.initTabs();
    }

    doSwitch(index) {
        const prevContents = this.items[this.index][1];
        const nextContents = this.items[index][1];

        const prevBar = this.items[this.index][0];
        const nextBar = this.items[index][0];

        this.hide(prevContents);
        this.show(nextContents);
        prevBar.removeAttribute('data-tabby-active');
        nextBar.setAttribute('data-tabby-active', '');

        this.index = index;
    }

    /**
     * init Tabs
     *
    */
    initTabs() {
        const bars = this.getSiblings(
            this.container.querySelector(`[data-tabby-bar]`),
        );

        const content = this.getSiblings(
            this.container.querySelector(`[data-tabby-content]`),
        );

        bars.forEach((bar, index) => {
            const attr = bar.getAttribute('data-tabby-bar');
            const partners = content.filter(item => item.getAttribute('data-tabby-content') === attr);

            partners.forEach((partner) => {
                if (index === 0) {
                    partner.style.display = 'block';
                    partner.style.opacity = '1';
                } else {
                    partner.style.display = 'none';
                }
            });

            this.items.push([bar, partners]);

            bar.addEventListener('click', () => {
                this.doSwitch(index);
            });
        });
    }

    getSiblings(element) {
        const arrayContainer = [element];

        let nextElement = element.nextElementSibling;

        if (!nextElement) {
            return arrayContainer;
        }

        while (nextElement) {
            arrayContainer.push(nextElement);

            nextElement = nextElement.nextElementSibling;
        }

        return arrayContainer;
    }

    show(els){
        els.forEach((el) => {
            const element = el;
            element.style.display = 'block';
        });
    }

    hide(els) {
        els.forEach((el) => {
            const element = el;
            element.style.display = 'none';
        });
    };
}

export { Tabs as default };
