import * as request from 'superagent';
import * as _ from 'lodash';
import { JsHelpers } from 'helpers/helpers';
import { Widget } from 'modules/widget';

class SearchWidget extends Widget {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._widgetType = 'search';
        this._endpoint = '/bi/search_filter_item/1/';
        this._config = config;
        this.page_size = 20;
        this.offset = 0;
    }

    render() {
        if(this.chartbox) {
            this.chartbox.render();
        }
    }

    fetchData() {
        let _this = this;
        let searchContainer = document.createElement("div");
        searchContainer.classList.add('search_list--container');
        searchContainer.classList.add('o-grid');
        searchContainer.classList.add('o-grid--small');
        searchContainer.classList.add('o-grid--contentcenter');
        searchContainer.classList.add('o-grid--wrap');

        let not_has_input = true;
        let space = 12 / (Object.keys(_this._config.extra_conf.search).length + 1);

        _.each(_this._config.extra_conf.search, (block, index) => {

            let midiv = document.createElement("div");
            midiv.classList.add('o-grid__item');
            midiv.classList.add('u-1/1@xs');
            midiv.classList.add('u-margin-small--vertical');
            midiv.classList.add('u-margin-none--bottom@xs');
            //midiv.classList.add('u-'+ space +'/12');
            //midiv.classList.add('u-1/1@xs');

            let mi = document.createElement("input");
            mi.setAttribute('type', 'text');
            mi.setAttribute('class', 'search_list');
            mi.classList.add('c-input');
            mi.classList.add('c-input--full');
            mi.classList.add('c-input--bordered'); 
            mi.setAttribute('placeholder', block);
            mi.setAttribute('name', index);
            if (JsHelpers.gup(index).length > 0 ){
                mi.setAttribute('value', decodeURI(JsHelpers.gup(index)[0] ) );
                not_has_input = false;
            }
            mi.onkeydown = function(e){
                if(e.keyCode == 13){
                    _this.offset = 0;
                    _this.search(false);
                }
            };

            midiv.appendChild(mi);
            searchContainer.appendChild(midiv);
        });

        let midiv = document.createElement("div");
        midiv.classList.add('o-grid__item');
        midiv.classList.add('u-1/1@xs');
        midiv.classList.add('u-margin-small--vertical');
        //midiv.classList.add('u-'+ space +'/12');
        //midiv.classList.add('u-1/1@xs');

        let mi = document.createElement("input");
        mi.setAttribute('type', 'submit');
        mi.setAttribute('value', 'Cerca');

        mi.classList.add('c-btn');
        mi.classList.add('c-btn--small');
        mi.classList.add('c-btn--full');
        mi.classList.add('c-btn--white');
        mi.classList.add('c-btn--uppercase');

        mi.onclick = function () {
            _this.offset = 0;
            _this.search(false);
        }

        midiv.appendChild(mi);

        searchContainer.appendChild(midiv);
        _this.element.parentNode.parentNode.parentNode.insertBefore(searchContainer, _this.element.parentNode.parentNode);

        _this.element.parentNode.parentNode.parentNode.classList.add('c-widget-search-single');

        _this.offset = 0;
        _this.search(not_has_input);
    }

    search(all) {

        this.element.parentNode.parentNode.classList.add('is-loading');

        if (document.getElementById("search_response_table_paging")){
            document.getElementById("search_response_table_paging").remove();
        }

        if (document.getElementById("search_response_table")){
            document.getElementById("search_response_table").remove();
        }

        let _this = this;
        let requests = [];
        let params = {
            debug: window.agregg.debugMode
        };
        let searchkeys = {};

        if (all){
            let first_key = Object.keys(this._config.extra_conf.search)[0];
            searchkeys[first_key] = "";
        }
        else {
            let inputs = document.querySelectorAll('.search_list');
            if(inputs != null) {
                inputs.forEach((input) => {
                    searchkeys[input.getAttribute("name")] = input.value;
                })
            }
        }

        let order = this._config.extra_conf.order;
        //order = "level_item_50_attr_1";

        let r = request.post(this._endpoint)
            .set('Accept', 'application/json')
            .send(
                {"searchkeys": searchkeys, "order": order, 'page_size': this.page_size, 'offset': this.offset }
            ) 
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });
        requests.push(r);

        // HANDLE REQUESTS RESPONSES
        Promise.all(requests).then(responses => {

            let acceptablesKey = [];
            _.each(this._config.extra_conf.field, field => {
                let l = field.split('_');
                acceptablesKey.push('level_item_' + l[0] + '_attr_' + l[1]  )
            })

            _.each(responses, blockData => {
                let tableData = blockData.data;
                let table = document.createElement('table');

                table.setAttribute('id', 'search_response_table');
                table.classList.add('c-table');
                table.classList.add('u-1/1');

                let tableBody = document.createElement('tbody');
                tableBody.classList.add('c-table__body');

                // add header
                let row = document.createElement('tr');
                row.classList.add('c-table__row');
                row.classList.add('c-table__row--spacedhead');
                let paging = document.createElement('div');

                if (blockData.count > 0 ){ //TODO ENABLE THIS for paging
                    paging.setAttribute('id', 'search_response_table_paging');
                    paging.classList.add('c-paging');

                    if (_this.offset + _this.page_size < blockData.count ){
                        let next_paging = document.createElement('div');
                        next_paging.classList.add('c-paging__item');
                        next_paging.innerHTML = 'AVANTI <svg class="c-nav__arrow o-icon"><use xlink:href="#icon-arrow-right"></use></svg>';
                        next_paging.onclick = function () {
                            _this.offset += _this.page_size;
                            console.log(_this.offset);
                            JsHelpers.doScrolling('#search_response_table', 1000);
                            _this.search(false);
                        }
                        paging.appendChild(next_paging);
                    }

                    let page_tot = parseInt(blockData.count / _this.page_size);
                    if (page_tot != 0 ){
                        let page_count = document.createElement('div');
                        page_count.classList.add('c-paging__count');
                        let c_page = (_this.offset / _this.page_size) + 1;
                        page_count.innerHTML = '' + c_page  + ' di ' + (parseInt(blockData.count / _this.page_size) + 1);
                        paging.appendChild(page_count);
                    }

                    if (_this.offset > 0){
                        let prev_paging = document.createElement('div');
                        prev_paging.classList.add('c-paging__item');
                        prev_paging.innerHTML = '<svg class="c-nav__arrow o-icon"><use xlink:href="#icon-arrow-left"></use></svg> INDIETRO ';
                        paging.appendChild(prev_paging);
                        prev_paging.onclick = function () {
                            _this.offset -= _this.page_size;
                            JsHelpers.doScrolling('#search_response_table', 1000);
                            _this.search(false);
                        }
                    }
                    
                }

                //add first empy element
                //this._config.extra_conf.headers = [''].concat(this._config.extra_conf.headers);

                this._config.extra_conf.headers.forEach(function(head, i) {
                    let cell = document.createElement('td');

                    let txt = document.createElement('div');
                    txt.classList.add('c-table__data');

                    let sp = document.createElement('span');
                    sp.innerHTML = head;
                    txt.appendChild(sp);

                    cell.appendChild(txt);
                    cell.classList.add('c-table__cell');
                    if (i == 0){
                        cell.classList.add('c-table__cell--first');
                    }
                    row.appendChild(cell);
                });

                tableBody.appendChild(row);


                // add other cells
                tableData.forEach(function(rowData) {
                    let json_attr = JSON.parse(rowData.attribute_json);

                    var to_hide = _this._config.extra_conf.to_hide;
                    //to_hide = ['level_item_50_attr_24', "LOMBARDIA"]
                    if (to_hide){
                        if (json_attr[to_hide[0]] == to_hide[1] ){
                            //return true; // go to next iteration
                            // gestito in import l'hide
                        }
                    }

                    let row = document.createElement('tr');
                    row.classList.add('c-table__row');
                    row.classList.add('c-table__row--spaced');

                    let link = '/cms/menu/1/' + _this._config.extra_conf.dashboard_result + '/?gff=' + rowData['kpi_filter_item_id'];

                    if (_this._config.extra_conf["not_group_filter_item_id"]){
                        link += '&gff=' + _this._config.extra_conf["not_group_filter_item_id"];
                    }

                    row.setAttribute("onclick", 'window.location="'+link+'"'); // add on row click

                    let cell = document.createElement('td');

                    let a = document.createElement('a');
                    let div = document.createElement('div');
                    div.innerHTML = '<svg class="c-nav__arrow o-icon"><use xlink:href="#icon-arrow-right"></use></svg>';
                    a.appendChild(div);

                    a.href = link;

                    cell.appendChild(a);
                    row.appendChild(cell);

                    tableBody.appendChild(row);


                    _.each(acceptablesKey, (keyName, key) => {
                        let cell = document.createElement('td');

                        let txt = document.createElement('div');
                        txt.classList.add('c-table__data');

                        let sp = document.createElement('span');
                        sp.innerHTML = typeof(json_attr[keyName]) != 'undefined' ? json_attr[keyName] : '';
                        txt.appendChild(sp);

                        cell.appendChild(txt);
                        cell.classList.add('c-table__cell');
                        row.appendChild(cell);
                    });
                });

                table.appendChild(tableBody);
                _this.element.appendChild(table);

                _this.element.appendChild(paging);

            });
            this.widget.loading = false;

            this.render();
        });
    }
}

export { SearchWidget }