// DOM HELPERS

function getSiblings(element, selector) {
    let siblings = [];
    let sibling = element.parentNode.firstChild;
    while(sibling) {
        if(sibling.nodeType === 1 && sibling !== element && sibling.matches(selector)) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
}

// JS HELPERS

function doScrolling(elementY, duration) { 
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start;
  
    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of scrolling.
        var time = timestamp - start;
        // Get percent of completion in range [0, 1].
        var percent = Math.min(time / duration, 1);
    
        window.scrollTo(0, startingY + diff * percent);
    
        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    })
}

function debounce(func, delay) {
    let inDebounce;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => {
            func.apply(context, args)
        }, delay);
    }
}

function slugify(text) {
    return text.toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

function getUrlParams(search) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
        const split = hash.indexOf('=');
        if (split < 0) {
            return Object.assign(params, {
                [hash]: null
            });
        }
        const key = hash.slice(0, split);
        const val = hash.slice(split + 1);
        return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
};

function gup(paramName){
    var sURL = window.document.URL.toString();
    var value =[];
    if (sURL.indexOf("?") > 0){
        var arrParams = sURL.split("?");
        var arrURLParams = arrParams[1].split("&");
        for (var i = 0; i<arrURLParams.length; i++){
            var sParam =  arrURLParams[i].split("=");
            if(sParam){
                if(sParam[0] == paramName){
                    if(sParam.length>0){
                        value.push(sParam[1].trim());
                    }
                }
            }
        }
    }
    return value.toString().split(',');
}

// EXPORT

const helpers = {
    DomHelpers: {
        getSiblings: getSiblings,
    },
    JsHelpers: {
        debounce: debounce,
        doScrolling: doScrolling,
        slugify: slugify,
        getUrlParams: getUrlParams,
        gup: gup,
    }
};

export default helpers;
export const DomHelpers = helpers.DomHelpers;
export const JsHelpers = helpers.JsHelpers;
