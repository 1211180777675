import * as request from 'superagent';
import * as _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

class Map {

    constructor(element, config, chartbox) {
        // PRIVATE PROPERTIES
        this._endpoint = '/bi/tree/';
        this._graphType = am4charts.MapChart;
        this._legend = config.legend;
        this._kpis = config.kpis;
        this._dashboardId = config.dashboardId;
        this._menuId = config.menuId;
        this._graphId = config.graphId;
        this._order_kpi_code = config.order_kpi_code;
        this._order_by_value = config.order_by_value;
        this._order_reverse = config.order_reverse;
        this._limit = config.limit;
        this._inheritCategory = config.inheritCategory;
        this._byKpiCode = config.byKpiCode;
        this._useKpiGroupCategory = config.useKpiGroupCategory;
        this._drillDown = config.defaultDrillDownId;
        this._containerConfig = config.container;
        this._filterParameters = config.filterParameters;
        this._axes = [];
        this._series = [];

        // PUBLIC PROPERTIES
        this.element = element;
        this.chartbox = chartbox;
        this.series = {};
        this.axes = {};
    }

    setExtraConfig(extraConfig) {
        if(!_.isUndefined(extraConfig) && extraConfig.p_id) {
            this._period = extraConfig.p_id;
        }
    }

    getDefaultConfig() {
        return {
            autoResize: true,
            decimalSeparator: ',',
            thousandsSeparator: '.',
        }
    }

    init() {
        am4core.options.commercialLicense = true;
        this.graph = am4core.create(this.element, this._graphType);
        //this.graph.geodata = am4geodata_worldLow;
        this.graph.chartContainer.wheelable = false;

        //this.graph.exporting.menu = new am4core.ExportMenu(); //disabled for now

        // Zoom control
        this.graph.zoomControl = new am4charts.ZoomControl();

        
        this.graph.homeZoomLevel = 3.5;
        /*this.graph.homeGeoPoint = {
            latitude: 41.5115448,
            longitude: 11.2102656
        };*/
          
        //let polygonSeries = this.graph.series.push(new am4charts.MapPolygonSeries());
        this.graph.projection = new am4charts.projections.Miller();

        // TODO Abilitare qui se si vuole la mappa degli altri paesi
        /*var polygonSeries = this.graph.series.push(new am4charts.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        polygonSeries.exclude = ["IT"];
        polygonSeries.geodata = am4geodata_worldLow;*/

        this.loading = true;
        this.fetchData();
    }

    render(graphData) {
        if(this.loading) {
            this.chartbox.element.classList.add('is-loading');
        } else {
            this.chartbox.element.classList.remove('is-loading');
        }

        if(graphData) {
            setTimeout(() => {
                
                let densities = [];
                _.each(graphData.features, blockData => {
                    // vuole il value
                    blockData.properties.value = blockData.properties.density;
                    densities.push(blockData.properties.density);
                });

                let polygonSeries = this.graph.series.push(new am4charts.MapPolygonSeries());
                polygonSeries.geodata = graphData;
                polygonSeries.useGeodata = true;
                polygonSeries.heatRules.push({
                    property: "fill",
                    target: polygonSeries.mapPolygons.template,
                    min: this.graph.colors.getIndex(1).brighten(1),
                    max: this.graph.colors.getIndex(1).brighten(-0.3)
                });

                let heatLegend = this.graph.createChild(am4charts.HeatLegend);
                heatLegend.series = polygonSeries;
                heatLegend.align = "right";
                heatLegend.width = am4core.percent(25);
                heatLegend.marginRight = am4core.percent(4);
                heatLegend.minValue = Math.min.apply(Math, densities);
                heatLegend.maxValue = Math.max.apply(Math, densities);
                // Set up custom heat map legend labels using axis ranges
                var minRange = heatLegend.valueAxis.axisRanges.create();
                minRange.value = heatLegend.minValue;
                minRange.label.text = Math.round(heatLegend.minValue * 10) / 10;
                var maxRange = heatLegend.valueAxis.axisRanges.create();
                maxRange.value = heatLegend.maxValue;
                maxRange.label.text = Math.round(heatLegend.maxValue * 10) / 10;

                // Blank out internal heat legend value axis labels
                heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function(labelText) {
                return "";
                });

                // Configure series tooltip
                var polygonTemplate = polygonSeries.mapPolygons.template;
                polygonTemplate.tooltipText = "{name}: {formatted_size_symbol}";

                // Create hover state and set alternative fill color
                var hs = polygonTemplate.states.create("hover");
                hs.properties.fill = am4core.color("#3c5bdc");
                
            }, 100); // WHY???
        }
    }

    getCustomParams() {
        return {
            custom_view: 'get_json_for_map'
        };
    }

    fetchData() {
        let requests = [];
        // PREPARE REQUESTS BASED ON KPI CONFIG
        _.each(this._kpis, kpi => {
            let params = {
                kpi: kpi.key,
                menu_id: this._menuId,
                from_who: 1,
                debug: window.agregg.debugMode
            };

            params = _.merge(params, this.getCustomParams());

            if(this._drillDown != undefined) {
                params['drill_down_id'] = this._drillDown;
            }
            if(this._period != undefined) {
                params['per'] = this._period;
            }
            let r = request.get(this._endpoint)
            .set('Accept', 'application/json')
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });
            requests.push(r);
        });
        // HANDLE REQUESTS RESPONSES
        Promise.all(requests).then(responses => {
            let graphData;
            _.each(responses, blockData => {
                graphData = blockData;
            });
            this.loading = false;
            this.render(graphData);
        });
    }

}  export { Map }