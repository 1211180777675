// forEach

(function () {
    if ( typeof NodeList.prototype.forEach === "function" ) return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
})();

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function (arr) {
arr.forEach(function (item) {
    if (item.hasOwnProperty('append')) {
    return;
    }
    Object.defineProperty(item, 'append', {
    configurable: true,
    enumerable: true,
    writable: true,
    value: function append() {
        var argArr = Array.prototype.slice.call(arguments),
        docFrag = document.createDocumentFragment();
        
        argArr.forEach(function (argItem) {
        var isNode = argItem instanceof Node;
        docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });
        
        this.appendChild(docFrag);
    }
    });
});
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

if (!Element.prototype.matches) {
    Element.prototype.matches = 
    Element.prototype.matchesSelector || 
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector || 
    Element.prototype.oMatchesSelector || 
    Element.prototype.webkitMatchesSelector ||
    function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;            
    };
}

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
    return;
    }
    Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
            this.parentNode.removeChild(this);
        }
    });

});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);