'use strict';

class Modal {

	constructor() {
		this.triggers = document.querySelectorAll( '.js-modal' );
		this.close = document.querySelectorAll( '.js-close-modal' );
		this.modals = document.querySelectorAll( '.c-modal' );
		this.modalInners = document.querySelectorAll( '.c-modal__inner' );

		this.listeners();
	}

	listeners() {
		window.addEventListener( 'keydown', this.keyDown );

		this.triggers.forEach( el => {
			el.addEventListener( 'click', this.openModal, false );
		} );

		this.modals.forEach( el => {
			el.addEventListener( 'transitionend', this.revealModal, false );
			el.addEventListener( 'click', this.backdropClose, false );
		} );

		this.close.forEach( el => {
			el.addEventListener( 'click', Modal.hideModal, false );
		} );

		this.modalInners.forEach( el => {
			//el.addEventListener( 'transitionend', this.closeModal, false );
		} );
	}

	keyDown( e ) {
		if ( 27 === e.keyCode && document.body.classList.contains( 'c-modal__body' ) ) {
			Modal.hideModal();
		}
	}

	backdropClose( el ) {
		if ( ! el.target.classList.contains( 'c-modal--visible' ) ) {
			return;
		}

		let backdrop =  el.currentTarget.dataset.backdrop !== undefined ? el.currentTarget.dataset.backdrop : true ;

		if ( backdrop === true ) {
			Modal.hideModal();
		}
	}

	static hideModal() {
		console.log('hideModal');
		let modalOpen = document.querySelector( '.c-modal.c-modal--visible' );

		modalOpen.querySelector( '.c-modal__inner' ).classList.remove( 'c-modal--reveal' );
		document.querySelector( '.c-modal__body' ).addEventListener( 'transitionend', Modal.modalBody, false );
		document.body.classList.add( 'modal-fadeOut' );

		modalOpen.classList.remove( 'c-modal--visible' );
	}

	/*closeModal( el ) {
		console.log('closeMOdal');
		if ( 'opacity' === el.propertyName && ! el.target.classList.contains( 'c-modal--reveal' ) ) {
			document.querySelector( '.c-modal.c-modal--visible' ).classList.remove( 'c-modal--visible' );
		}
	}*/

	openModal( el ) {
		if ( ! el.currentTarget.dataset.modal ) {
			//console.error( 'No data-modal attribute defined!' );
			return;
		}

		if ( el.currentTarget.dataset.modalTarget == "mobile" && window.innerWidth < 768 ) {
			//console.error( 'only mobile' );
		}
		else {
			return;
		}

		let modalID = el.currentTarget.dataset.modal;
		let modal = document.getElementById( modalID );

		
		let cb = modal.querySelector('.c-modal__inner');

		//cb.style.height = 'auto';
		modal.style.overflowY = 'auto';

		document.body.classList.add( 'c-modal__body' );
		modal.classList.add( 'c-modal--visible' );
	}

	revealModal( el ) {
		if ( 'opacity' === el.propertyName && el.target.classList.contains( 'c-modal--visible' ) ) {
			el.target.querySelector( '.c-modal__inner' ).classList.add( 'c-modal--reveal' );
		}
	}

	static modalBody( el ) {
		if ( 'opacity' === el.propertyName && el.target.classList.contains( 'c-modal' ) && ! el.target.classList.contains( 'c-modal--visible' ) ) {
			document.body.classList.remove( 'c-modal__body', 'modal-fadeOut' );
		}
	}

}

new Modal();