class Accordion {
   constructor(element) {
      this.element = element;
      this.init();
   }

   init(){
      /*let cBody = this.element.querySelectorAll('.c-chartbox__body')[0];
      this.element.classList.remove('is-open');
      cBody.classList.add('u-hide');*/

      this.element.getElementsByClassName("js-accordion__title")[0].addEventListener('click', () => { 
         let isOpen = this.element.classList.contains('is-open');
         let cBody = this.element.getElementsByClassName('js-accordion__content')[0];

         if (isOpen){
            this.element.classList.remove('is-open');
            cBody.classList.add('u-hide');
         }
         else {
            this.element.classList.add('is-open');
            cBody.classList.remove('u-hide');
         }
      });
   }

}

export { Accordion }