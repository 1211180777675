import * as request from 'superagent';
import * as _ from 'lodash';
import { JsHelpers } from 'helpers/helpers';
import { Widget } from 'modules/widget';

class AgenciesWidget extends Widget {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._widgetType = 'agencies';
        this._endpoint = '/bi/get_agencies_array/1/';
        this._config = config;
    }

    render() {
        if(this.chartbox) {
            this.chartbox.render();
        }
    }

    fetchData() {
        let _this = this;
        let agenciesContainer = document.createElement("div");
        agenciesContainer.classList.add('agencies_list');
        agenciesContainer.classList.add('o-grid');
        agenciesContainer.classList.add('o-grid--small');
        agenciesContainer.classList.add('o-grid--contentcenter');
        agenciesContainer.classList.add('o-grid--wrap');

        _this.agencies(true);
    }

    agencies(all) {
        let _this = this;
        let requests = [];
        let params = {
            debug: window.agregg.debugMode
        };
        
        let item_level_id = this._config.extra_conf.item_level_id;
        let item_key = this._config.extra_conf.item_key;

        // TODO nel post dovrò passare: 
        // 'id': '153' -> codice agenzia attuale
        // 'item_level_id': 51
        // 'item_key' : id_attributo del level su cui fare la ricerca (level_item_51_attr_%s)
        // al click su details faccio il redirect cambiado gff e il filtro se global o no!
        let r = request.post(this._endpoint)
            .set('Accept', 'application/json')
            .send(
                {
                    "id": JsHelpers.gup('gff')[0],
                    "item_key": item_key,
                    "item_level_id": item_level_id
                }
            ) 
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });
        requests.push(r);

        // HANDLE REQUESTS RESPONSES
        Promise.all(requests).then(responses => {
            let _this = this;
            let acceptablesKey = [];
            _.each(this._config.extra_conf.field, field => {
                let l = field.split('_');
                acceptablesKey.push('level_item_' + l[0] + '_attr_' + l[1]  )
            })

            _.each(responses, blockData => {
                let tableData = blockData.data;

                if (tableData.length <= 1){
                    // HO solo me stessa
                    this.empty = true;
                    //let accordionContainer = document.querySelectorAll('.js-accordion-container');
                    // accordionContainer.forEach(function(accordion) { 
                    //     accordion.classList.add('u-hide');
                    // });
                    //this.element.parentNode.parentNode.parentNode.
                    //this.element.parentNode.parentNode.parentNode.classList.add('u-margin-none');
                    return;
                }
                else {
                    let accordionContainer = document.querySelectorAll('.js-accordion-container');
                    accordionContainer.forEach(function(accordion) { 
                        accordion.classList.remove('u-hide');
                    });
                }

                let table = document.createElement('table');
                if (document.getElementById("agencies_response_table")){
                    document.getElementById("agencies_response_table").remove();
                }

                table.setAttribute('id', 'agencies_response_table');
                //table.classList.add('c-agencies');
                table.classList.add('c-table');
                table.classList.add('u-1/1');

                let tableBody = document.createElement('tbody');
                tableBody.classList.add('c-table__body');

                let not_group_filter_item_id = _this._config.extra_conf['not_group_filter_item_id'];
                let group_filter_item_id = _this._config.extra_conf['group_filter_item_id'];
                let mom_id = undefined;
                let mom_text = _this._config.extra_conf['mom_text'];
                let mom_attr_id = _this._config.extra_conf['mom_attr_id'];
                let aggregata_id = _this._config.extra_conf['star_not_grupped'];
                
                tableData.forEach(function(rowData) {
                    let json_attr = JSON.parse(rowData.attribute_json);
                    if (json_attr['level_item_' + item_level_id + '_attr_' + mom_attr_id ].toLowerCase() == mom_text.toLowerCase() ){
                        mom_id = rowData['kpi_filter_item_id'];
                    }
                });

                // add other cells
                tableData.forEach(function(rowData) {
                    let json_attr = JSON.parse(rowData.attribute_json);

                    let row = document.createElement('tr');
                    row.classList.add('c-table__row');
                    //row.classList.add('c-table__row--spaced');
                    
                    let link = window.location.pathname + '?gff=' + rowData['kpi_filter_item_id'];
                    // if (rowData['kpi_filter_item_id'] == JsHelpers.gup('gff')[0] || rowData['kpi_filter_item_id'] == JsHelpers.gup('gff')[1]) {
                    //     row.classList.add('c-table__row--iscurrent');
                    // }
                    if (rowData['kpi_filter_item_id'] == JsHelpers.gup('gff')[0] && JsHelpers.gup('gff')[1] == not_group_filter_item_id){
                        row.classList.add('c-table__row--iscurrent');
                    }

                    link += '&gff=' + not_group_filter_item_id;

                    row.setAttribute("onclick", 'window.location="'+link+'"'); // add on row click

                    let cell = document.createElement('td');
                    cell.width=18;

                    let a = document.createElement('a');
                    let div = document.createElement('div');
                    
                    div.innerHTML = '<svg class="c-agencies__arrow o-icon"><use xlink:href="#icon-arrow-right"></use></svg>';
                    a.appendChild(div);

                    a.href = link;

                    cell.appendChild(a);
                    row.appendChild(cell);

                    
                    if (mom_id == rowData['kpi_filter_item_id']){
                        tableBody.prepend(row);
                    }
                    else {
                        tableBody.appendChild(row);
                    }

                    _.each(acceptablesKey, (keyName, key) => {
                        let cell = document.createElement('td');

                        let txt = document.createElement('div');
                        txt.classList.add('c-table__data');

                        let sp = document.createElement('span');
                        sp.innerHTML = typeof(json_attr[keyName]) != 'undefined' ? json_attr[keyName] : '';
                        txt.appendChild(sp);

                        cell.appendChild(txt);
                        cell.classList.add('c-table__cell');
                        row.appendChild(cell);
                    });

                    let cellitemlast = document.createElement('td');
                    cellitemlast.classList.add('c-table__cell');
                    cellitemlast.classList.add('c-table__cell--text');
                    if (mom_id == rowData['kpi_filter_item_id']){
                        let celldiv = document.createElement('div');
                        celldiv.classList.add('c-table__data');
                        let txt = document.createElement('span');
                        txt.innerHTML = 'principale';
                        celldiv.appendChild(txt);
                        cellitemlast.appendChild(celldiv);
                    }
                    row.appendChild(cellitemlast);

                });

                // SOLO SE AGEZIA MADRE
                let rowfinal = document.createElement('tr');
                rowfinal.classList.add('c-table__row');
                let page_title_obj = document.getElementsByClassName('c-content__header__title')[0];
                //page_title_obj.classList.add('u-invisible')
                let page_title = page_title_obj.innerHTML;
                if (JsHelpers.gup('gff')[1] != not_group_filter_item_id){
                    rowfinal.classList.add('c-table__row--iscurrent');
                    page_title = page_title.replace('Agenzia', 'Gestione');
                    document.querySelector('.c-content__header__title').innerHTML = page_title;
                }
                
                //rowfinal.classList.add('c-table__row--spaced');
                let link = window.location.pathname + '?gff=' + mom_id;

                let cell1 = document.createElement('td');

                let a = document.createElement('a');
                let div1 = document.createElement('div');
                
                div1.innerHTML = '<svg class="c-agencies__arrow o-icon"><use xlink:href="#icon-arrow-right"></use></svg>';
                a.appendChild(div1);
                cell1.appendChild(a);
                rowfinal.appendChild(cell1);
                
                let cell = document.createElement('td');
                let celldiv = document.createElement('div');
                celldiv.classList.add('c-table__data');

                let txt = document.createElement('span');
                let aggr_star = document.createElement('span');

                let star = '';

                tableData.forEach(function(rowData) {
                    let json_attr = JSON.parse(rowData.attribute_json);
                    if (json_attr['level_item_' + item_level_id + '_attr_' + mom_attr_id ].toLowerCase() == mom_text.toLowerCase() ){
                        mom_id = rowData['kpi_filter_item_id'];
                        star = json_attr[aggregata_id ]
                    }
                });
                
                if (star && star.indexOf('icon-star-o') > 0){
                    //var str2 = star.replace('icon-star-o', 'icon-star-o-dark');
                    aggr_star.innerHTML = star;
                }
                else if (star && star.indexOf('icon-star-half-full') > 0){
                    //var str2 = star.replace('icon-star-half-full', 'icon-star-half-full-dark');
                    aggr_star.innerHTML = star;
                }
                else if (star && star.indexOf('icon-star') > 0){
                    //var str2 = star.replace('icon-star', 'icon-star-dark');
                    aggr_star.innerHTML = star;
                }
                

                if (JsHelpers.gup('gff')[1] == not_group_filter_item_id){
                    txt.innerHTML = 'Visualizza gestione aggregata';
                }
                else {
                    txt.innerHTML = 'Gestione aggregata';
                    //link += '&gff=' + not_group_filter_item_id;
                }
                link += '&gff=' + group_filter_item_id;
                celldiv.appendChild(txt);
                rowfinal.setAttribute("onclick", 'window.location="'+link+'"'); // add on row click
                cell.appendChild(celldiv);
                cell.classList.add('c-table__cell');
                cell.classList.add('c-table__cell--text');
                cell.setAttribute('colspan', acceptablesKey.length - 1);
                rowfinal.appendChild(cell);


                let cell2 = document.createElement('td');
                let celldiv2 = document.createElement('div');
                celldiv2.classList.add('c-table__data');
                celldiv2.appendChild(aggr_star);
                cell2.appendChild(celldiv2);
                cell2.classList.add('c-table__cell');
                cell2.classList.add('c-table__cell--text');
                cell2.setAttribute('colspan', 1);
                rowfinal.appendChild(cell2);

                let celllast = document.createElement('td');
                celllast.classList.add('c-table__cell');
                celllast.classList.add('c-table__cell--text');
                rowfinal.appendChild(celllast);

                tableBody.appendChild(rowfinal);


                table.appendChild(tableBody);

                let tableContainer = document.createElement('div');
                tableContainer.classList.add('c-agencies');
                tableContainer.appendChild(table);
                _this.element.appendChild(tableContainer);

               
            });
            this.widget.loading = false;

            this.render();

            let c_ag = document.querySelectorAll('.c-agencies');
            if (c_ag.length  > 0){
                c_ag[0].querySelectorAll('.star_img').forEach(function(start_el) { 
                    if (start_el.innerHTML.indexOf('icon-star-o') > 0){
                        var str2 = start_el.innerHTML.replace('icon-star-o', 'icon-star-o-dark');
                        start_el.innerHTML = str2;
                    }
                    else if (start_el.innerHTML.indexOf('icon-star-half-full') > 0){
                        var str2 = start_el.innerHTML.replace('icon-star-half-full', 'icon-star-half-full-dark');
                        start_el.innerHTML = str2;
                    }
                    else if (start_el.innerHTML.indexOf('icon-star') > 0){
                        var str2 = start_el.innerHTML.replace('icon-star', 'icon-star-dark');
                        start_el.innerHTML = str2;
                    }
                });
            }
        });
    }
}

export { AgenciesWidget }