import scssVars from 'helpers/scss-var-mapper';

class Sidebar {

    constructor() {
        this.element = document.querySelector('.js-sidebar');
        if(this.element != null) {
            this.toggleElement = document.querySelector('.js-sidebar-toggle');
            this.logoElement = document.querySelector('.js-sidebar-logo');
            if(this.toggleElement != null) {
                this.bindEvents();
            }
        }
    }

    bindEvents() {
        this.toggleElement.addEventListener('click', (event) => {
            this.toggle();
        });

        if (window.matchMedia) {
            const mediaQuery = window.matchMedia(scssVars.breakpoints['tablet-landscape']);
            mediaQuery.addListener((event) => {
                this.onResize(mediaQuery);
            });
            this.onResize(mediaQuery);
        }
    }

    toggle() {
        this.element.classList.toggle('is-minified');
        if(this.logoElement != null) {
            this.logoElement.classList.toggle('is-minified');
        }
    }

    open() {
        this.element.classList.remove('is-minified');
        if(this.logoElement != null) {
            this.logoElement.classList.remove('is-minified');
        }
    }

    close() {
        this.element.classList.add('is-minified');
        if(this.logoElement != null) {
            this.logoElement.classList.add('is-minified');
        }
    }

    onResize(mediaQuery) {
        if(mediaQuery.matches) {
            this.open();
        } else {
            this.close();
        }
    }

}

export { Sidebar }
