import PerfectScrollbar from 'perfect-scrollbar';

import { JsHelpers } from 'helpers/helpers';

class Scrollbar {

   constructor(element) {
       this.element = element;
       this.scrollbar = new PerfectScrollbar(this.element);
       this.addClasses();
       this.bindEvents();
   }

   addClasses() {
       this.element.classList.add('o-scroll');
       this.scrollbar.scrollbarXRail.classList.add('o-scroll__rail');
       this.scrollbar.scrollbarXRail.classList.add('o-scroll__rail--x');
       this.scrollbar.scrollbarYRail.classList.add('o-scroll__rail');
       this.scrollbar.scrollbarYRail.classList.add('o-scroll__rail--y');
       this.scrollbar.scrollbarX.classList.add('o-scroll__thumb');
       this.scrollbar.scrollbarX.classList.add('o-scroll__thumb--x');
       this.scrollbar.scrollbarY.classList.add('o-scroll__thumb');
       this.scrollbar.scrollbarY.classList.add('o-scroll__thumb--y');
   }

   bindEvents() {
       window.addEventListener('resize', JsHelpers.debounce(() => {
           this.render();
       }));
   }

   render() {
       this.scrollbar.update();
       if(this.scrollbar.scrollbarXActive) {
           this.element.classList.add('o-scroll--horizontal');
       } else {
           this.element.classList.remove('o-scroll--horizontal');
       }
   }



}

export { Scrollbar }